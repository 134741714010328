import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import { Seo, Basic as AltButton, Loading, PageHeader } from '../components'
import CreditCard from '../components/CreditCard'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Styles
import { PaymentInfoContainer, StyledForm } from '../styles/LoginPage.styles'

const PaymentInfo = ({ location }) => {
  const { isAuthenticated, getSelectedCard, cardsOnFile, updateSelectedCard } =
    useAuthContext()
  const {
    autoShipId,
    autoShipData,
    handleGetAutoShipById,
    manageAutoShip,
    handleSetAutoShipState,
  } = useCartContext()
  const { isStoreLoading } = useStoreContext()
  const [selectedCardId, setSelectedCardId] = useState(
    getSelectedCard().creditCardGuid
  )
  const {
    prismicData: {
      prismicPaymentInfoPage: {
        add_new_credit_debit_card,
        loading_cards,
        payment_information,
        update_payment_info,
      },
    },
  } = usePrismic()

  const returnUrl = location?.state?.returnUrl || '/cart'

  useEffect(() => {
    if (!isAuthenticated) navigate('/')
  }, [])

  const onSubmit = async event => {
    event.preventDefault()
    const selectedCard = cardsOnFile.find(({ creditCardGuid }) => {
      return creditCardGuid === selectedCardId
    })

    if (returnUrl === '/cart') {
      // TODO: need to add in how we store the selected card
      updateSelectedCard(selectedCard)
    } else {
      if (!autoShipId) {
        handleSetAutoShipState({
          autoShipPaymentInformationId:
            selectedCard.associatePaymentInformationId,
        })
      } else {
        const data = {
          autoShipId,
          dateNextProcess: autoShipData.dateNextProcess,
          isActive: true,
          associatePaymentInformationId:
            selectedCard.associatePaymentInformationId,
        }
        await manageAutoShip
          .update(data, handleGetAutoShipById)
          .then(() => toast.success('Subscription payment info updated'))
      }
    }
    navigate(returnUrl)
  }
  if (isStoreLoading)
    return <Loading loading={isStoreLoading} message={loading_cards[0].text} />
  return (
    <>
      <Seo title={update_payment_info[0].text} />
      <PageHeader exitRoute={returnUrl}>
        {payment_information[0].text}
      </PageHeader>
      <PaymentInfoContainer>
        <StyledForm onSubmit={onSubmit}>
          {cardsOnFile?.length > 0 &&
            cardsOnFile.map(creditCard => (
              <CreditCard
                creditCard={creditCard}
                selectedCardId={selectedCardId}
                setSelectedCardId={setSelectedCardId}
                key={creditCard.creditCardGuid}
              />
            ))}
        </StyledForm>
        <AltButton
          onClick={() =>
            navigate('/add-card', {
              state: {
                returnUrl,
              },
            })
          }
        >
          {add_new_credit_debit_card[0].text}
        </AltButton>
      </PaymentInfoContainer>
    </>
  )
}

export default PaymentInfo
